const fieldmessages = {
    error: {
        color: { value: '{colors.font.error.value}' },
        fontSize: { value: '{fontSizes.small.value}' },
    },
    description: {
        color: { value: '{colors.font.secondary.value}' },
        fontStyle: { value: 'italic' },
        fontSize: { value: '{fontSizes.small.value}' },
    },
};

export { fieldmessages };
