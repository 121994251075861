const input = {
    color: { value: '{components.fieldcontrol.color.value}' },
    borderColor: { value: '{components.fieldcontrol.borderColor.value}' },
    fontSize: { value: '{components.fieldcontrol.fontSize.value}' },
    _focus: {
        borderColor: {
            value: '{components.fieldcontrol._focus.borderColor.value}',
        },
    },
};

export { input };
