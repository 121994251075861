const field = {
    // default styles
    gap: { value: '{space.xs.value}' },
    fontSize: { value: '{fontSizes.medium.value}' },
    flexDirection: { value: 'column' },
    // Adjust base fontSize and gap for small and large sizes
    small: {
        gap: { value: '{space.xxxs.value}' },
        fontSize: { value: '{fontSizes.small.value}' },
    },
    large: {
        gap: { value: '{space.small.value}' },
        fontSize: { value: '{fontSizes.large.value}' },
    },
    label: {
        color: { value: '{colors.font.secondary.value}' },
    },
};

export { field };
