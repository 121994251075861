const copy = {
    fontSize: { value: '{fontSizes.xs}' },
    gap: { value: '{space.relative.medium}' },
    svg: {
        path: {
            fill: {
                value: '{colors.font.primary}',
            },
        },
    },
    toolTip: {
        bottom: { value: '{space.large}' },
        color: { value: '{colors.teal.100}' },
        fontSize: { value: '{fontSizes.xxs}' },
    },
};

export { copy };
