const fieldgroup = {
    gap: { value: '{space.zero.value}' },
    vertical: {
        alignItems: { value: 'center' },
    },
    outer: {
        alignItems: { value: 'center' },
    },
};

export { fieldgroup };
