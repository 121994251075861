import { initializeModel, generateGraphQLDocument, buildGraphQLVariables, flattenItems, authModeParams, getCustomHeaders, } from '../APIClient';
export function getFactory(client, modelIntrospection, model, operation, useContext = false) {
    const getWithContext = async (contextSpec, arg, options) => {
        return _get(client, modelIntrospection, model, arg, options, operation, contextSpec);
    };
    const get = async (arg, options) => {
        return _get(client, modelIntrospection, model, arg, options, operation);
    };
    return useContext ? getWithContext : get;
}
async function _get(client, modelIntrospection, model, arg, options, operation, context) {
    const { name } = model;
    const query = generateGraphQLDocument(modelIntrospection, name, operation, options);
    const variables = buildGraphQLVariables(model, operation, arg, modelIntrospection);
    try {
        const auth = authModeParams(client, options);
        const headers = getCustomHeaders(client, options?.headers);
        const { data, extensions } = context
            ? (await client.graphql(context, {
                ...auth,
                query,
                variables,
            }, headers))
            : (await client.graphql({
                ...auth,
                query,
                variables,
            }, headers));
        // flatten response
        if (data) {
            const [key] = Object.keys(data);
            const flattenedResult = flattenItems(data)[key];
            if (options?.selectionSet) {
                return { data: flattenedResult, extensions };
            }
            else {
                // TODO: refactor to avoid destructuring here
                const [initialized] = initializeModel(client, name, [flattenedResult], modelIntrospection, auth.authMode, auth.authToken, !!context);
                return { data: initialized, extensions };
            }
        }
        else {
            return { data: null, extensions };
        }
    }
    catch (error) {
        if (error.errors) {
            // graphql errors pass through
            return error;
        }
        else {
            // non-graphql errors re re-thrown
            throw error;
        }
    }
}
