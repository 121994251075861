const placeholder = {
    borderRadius: { value: '{radii.small.value}' },
    transitionDuration: { value: '{time.long.value}' },
    startColor: { value: '{colors.background.secondary.value}' },
    endColor: { value: '{colors.background.tertiary.value}' },
    // sizes
    small: {
        height: { value: '{space.small.value}' },
    },
    default: {
        height: { value: '{space.medium.value}' },
    },
    large: {
        height: { value: '{space.large.value}' },
    },
};

export { placeholder };
