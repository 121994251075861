const phonenumberfield = {
    color: { value: '{components.fieldcontrol.color}' },
    borderColor: { value: '{components.fieldcontrol.borderColor}' },
    fontSize: { value: '{components.fieldcontrol.fontSize}' },
    _focus: {
        borderColor: { value: '{components.fieldcontrol._focus.borderColor}' },
    },
};

export { phonenumberfield };
