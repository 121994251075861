import kebabCase from 'lodash/kebabCase.js';
import usesReference from 'style-dictionary/lib/utils/references/usesReference.js';
import '@aws-amplify/core/internals/utils';
import '../utils/setUserAgent/constants.mjs';
import { isObject, has, isString } from '../utils/utils.mjs';

const CSS_VARIABLE_PREFIX = 'amplify';
// Important: these properties should not be altered in
// order to maintain the expected order of the CSS `box-shadow` property
const SHADOW_PROPERTIES = [
    'offsetX',
    'offsetY',
    'blurRadius',
    'spreadRadius',
    'color',
];
function referenceValue(value) {
    if (!value)
        return '';
    if (usesReference(value)) {
        const path = value.replace(/\{|\}/g, '').replace('.value', '').split('.');
        return `var(--${cssNameTransform({ path })})`;
    }
    return value;
}
function cssValue(token) {
    const { value } = token;
    if (isString(value)) {
        return referenceValue(value);
    }
    if (isShadowTokenObject(value)) {
        return SHADOW_PROPERTIES.map((property) => {
            return referenceValue(
            // lookup property against `token` first for custom non-nested value, then lookup
            // property against `value` for design token value
            isShadowTokenObject(token) ? token[property] : value[property]);
        }).join(' ');
    }
    return value;
}
function cssNameTransform({ path = [] }) {
    return `${kebabCase([CSS_VARIABLE_PREFIX, ...path].join(' '))}`;
}
/**
 * Helper function to test if something is a design token or not.
 * Used in the React component style props.
 *
 * @param value - thing to test if it is a design token or not
 * @returns boolean
 */
function isDesignToken(value) {
    return isObject(value) && has(value, 'value');
}
function isShadowTokenObject(value) {
    return isObject(value) && has(value, 'offsetX');
}
/**
 * Recursive function that will walk down the token object
 * and perform the setupToken function on each token.
 * Similar to what Style Dictionary does.
 */
function setupTokens({ tokens, path = [], setupToken, }) {
    if (has(tokens, 'value')) {
        return setupToken({ token: tokens, path });
    }
    const output = {};
    for (const name in tokens) {
        if (has(tokens, name)) {
            const value = tokens[name];
            const nextTokens = isObject(value) ? value : { value };
            output[name] = setupTokens({
                tokens: nextTokens,
                path: path.concat(name),
                setupToken,
            });
        }
    }
    return output;
}

export { CSS_VARIABLE_PREFIX, cssNameTransform, cssValue, isDesignToken, isShadowTokenObject, setupTokens };
